export default {
  // 开关 - IAM是否支持收发短信
  SMS_OPEN: false,
  // 开关 - IAM是否支持收发邮件
  EMAIL_OPEN: false,
  // 开关 - IAM是否支持用户名密码注册
  REGISTER_USERNAME_OPEN: true,
  // 默认domain
  DEFAULT_DOMAIN: '',
  // 登录页默认登录用户类型。支持：USER_MAIN, USER_SUB.
  DEFAULT_LOGIN_USER_TYPE: 'USER_MAIN',
  // 是否显示主用户相关组件。true：显示，false：不显示。 (IF_SHOW_MAIN =false && DEFAULT_LOGIN_USER_TYPE = USER_SUB)
  IF_SHOW_MAIN: true,
  // client_uuid
  client_uuid: '46872f7ac16e4fdb9eef218ff76ffda3',
  // 系统token
  tokenKey: 'iam_p_access_token',
  // 刷新token
  refreshKey: 'iam_p_refresh_token',
  // 登录页获取Logo接口地址
  LOGIN_CUSTOM_LOGO_URL: 'https://dev-cloud.ainnovation.com/api_ups/v1/management/oem/logo',
  // 登录按钮下方提示文字
  // 提示文字的样式（linkApply），可内置，可定制开发
  LOGIN_CUSTOM_PROMPT: '',
  // 前缀
  img_base_url: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_PROXY_URL : location.origin
}
