<template>
  <div id="app">
    <router-view v-if="isReload" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getToken } from '@/utils/auth'

export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isReload: true
    }
  },
  async created () {
    // 每次刷新之后重新获取菜单数据
    if (location.pathname != '/login' && getToken()) {
      this.getMenuList()
    }

    await [this.getSystemInfo(), this.getSystemInfoImage()]
  },
  methods: {
    ...mapActions(['getMenuList', 'getSystemInfo', 'getSystemInfoImage']),
    reload () {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>

<style src="./styles/reset.css"/>

