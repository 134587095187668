import router from './index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import { getToken, get_refresh_token, removeToken } from '@/utils/auth'
import { check_access_token } from '@/api'

NProgress.configure({ showSpinner: false })
// 页面白名单
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  console.log(to)

  NProgress.start()
  if (getToken()) {
    /* has token*/
    if (to.path === '/login') {
      // 验证token是否有效
      check_access_token().then(res => {
        // token已过期
        if (res.status) {
          if (get_refresh_token()) {
            // 刷新token
            store.dispatch('resetToken').then(response => {
              if (!response.code && response.msg == 'success') {
                next({ path: '/' })
                if (to.query.need_redirect && to.query.redirect_url) {
                  location.href = to.query.redirect_url
                }
              } else {
                return Promise.reject(response)
              }
            }).catch(() => {
              removeToken() // 清除cookie
              next(to.fullPath) // 重定向到登录页
            })
          } else {
            next(`/login`)
          }
        } else {
          // token没有过期 直接进入首页
          next({ path: '/' })
          if (to.query.need_redirect && to.query.redirect_url) {
            location.href = to.query.redirect_url
          }
        }
      }).catch(() => {
        next({ path: '/' })
      })
      // 重定向存储地址
      if (to.fullPath.includes('client_uuid') || to.fullPath.includes('need_redirect') || to.fullPath.includes('redirect_url')) {
        sessionStorage.setItem('fullPath', to.fullPath)
      }
      NProgress.done()
    } else {
      next()
    }
  } else {
    if (get_refresh_token()) {
      if (!(to.fullPath.indexOf('redirect') > -1) || (to.fullPath.indexOf('need_redirect') > -1)) {
        // 刷新token
        store.dispatch('resetToken').then(response => {
          if (!response.code && response.msg == 'success') {
            if (to.query.need_redirect && to.query.redirect_url) {
              location.href = to.query.redirect_url
            }
            next({ path: to.fullPath })
          } else {
            return Promise.reject(response)
          }
        }).catch(() => {
          removeToken() // 清除cookie
          next(to.fullPath) // 重定向到登录页
        })
      }
    } else {
      // 没有token
      if (whiteList.indexOf(to.path) > -1 || JSON.stringify(to.params) !== '{}') {
        // 在免登录白名单，直接进入
        next()
      } else {
        // 否则全部重定向到登录页
        next(`/login?redirect=${to.fullPath}`)
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
