<template>
  <div
    style="width: 100%"
    :class="{
      'next-loading': show,
      'next-open': show,
      'next-loading-inline': show,
    }"
  >
    <div v-if="show" class="next-loading-tip">
      <div class="next-loading-indicator">
        <div class="next-loading-fusion-reactor">
          <span class="next-loading-dot" />
          <span class="next-loading-dot" />
          <span class="next-loading-dot" />
          <span class="next-loading-dot" />
        </div>
      </div>
      <div class="next-loading-tip-content">{{ text }}</div>
    </div>
    <div class="next-loading-component next-loading-wrap">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.next-loading.next-open {
  pointer-events: none;
}
.next-loading {
  margin-bottom: 5px;
}
.next-loading {
  margin-bottom: 5px;
}
.next-loading {
  margin-bottom: 5px;
}
.next-loading-inline {
  display: inline-block;
}
.next-loading {
  position: relative;
}

.next-loading-tip {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.next-loading .next-loading-component {
  opacity: 0.7;
  -webkit-filter: blur(1px);
  filter: blur(1px);
  -webkit-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=1, MakeShadow=false)";
  filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=1, MakeShadow=false)";
  position: relative;
  pointer-events: none;
}
.next-loading-masker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0.2;
  background: #fff;
}
.next-loading-fusion-reactor {
  display: inline-block;
  width: 48px;
  width: var(--loading-large-size, 48px);
  height: 48px;
  height: var(--loading-large-size, 48px);
  position: relative;
  margin: 0;
  -webkit-animation-duration: 5.6s;
  -moz-animation-duration: 5.6s;
  -o-animation-duration: 5.6s;
  animation-duration: 5.6s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: nextVectorRoute;
  -moz-animation-name: nextVectorRoute;
  -o-animation-name: nextVectorRoute;
  animation-name: nextVectorRoute;
}

.next-loading-fusion-reactor .next-loading-dot:first-child {
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-name: nextVectorDotsX;
  -moz-animation-name: nextVectorDotsX;
  -o-animation-name: nextVectorDotsX;
  animation-name: nextVectorDotsX;
}

.next-loading-fusion-reactor .next-loading-dot:nth-child(2) {
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.8;
  -webkit-animation-name: nextVectorDotsY;
  -moz-animation-name: nextVectorDotsY;
  -o-animation-name: nextVectorDotsY;
  animation-name: nextVectorDotsY;
}

.next-loading-fusion-reactor .next-loading-dot:nth-child(3) {
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -webkit-animation-name: nextVectorDotsXR;
  -moz-animation-name: nextVectorDotsXR;
  -o-animation-name: nextVectorDotsXR;
  animation-name: nextVectorDotsXR;
}

.next-loading-fusion-reactor .next-loading-dot:nth-child(4) {
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  -webkit-animation-name: nextVectorDotsYR;
  -moz-animation-name: nextVectorDotsYR;
  -o-animation-name: nextVectorDotsYR;
  animation-name: nextVectorDotsYR;
}

.next-loading-medium-fusion-reactor {
  width: 32px;
  width: var(--loading-medium-size, 32px);
  height: 32px;
  height: var(--loading-medium-size, 32px);
}

.next-loading-medium-fusion-reactor .next-loading-dot {
  width: 8px;
  width: var(--loading-medium-dot-size, 8px);
  height: 8px;
  height: var(--loading-medium-dot-size, 8px);
}

.next-loading-fusion-reactor .next-loading-dot {
  position: absolute;
  margin: auto;
  width: 12px;
  width: var(--loading-large-dot-size, 12px);
  height: 12px;
  height: var(--loading-large-dot-size, 12px);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #0064c8;
  background: var(--loading-dot-color, #0064c8);
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 1.4s;
  -moz-animation-duration: 1.4s;
  -o-animation-duration: 1.4s;
  animation-duration: 1.4s;
}

.next-loading-fusion-reactor .next-loading-dot:first-child {
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-name: nextVectorDotsX;
  -moz-animation-name: nextVectorDotsX;
  -o-animation-name: nextVectorDotsX;
  animation-name: nextVectorDotsX;
}

.next-loading-fusion-reactor .next-loading-dot:nth-child(2) {
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.8;
  -webkit-animation-name: nextVectorDotsY;
  -moz-animation-name: nextVectorDotsY;
  -o-animation-name: nextVectorDotsY;
  animation-name: nextVectorDotsY;
}

.next-loading-fusion-reactor .next-loading-dot:nth-child(3) {
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0.6;
  -webkit-animation-name: nextVectorDotsXR;
  -moz-animation-name: nextVectorDotsXR;
  -o-animation-name: nextVectorDotsXR;
  animation-name: nextVectorDotsXR;
}

.next-loading-fusion-reactor .next-loading-dot:nth-child(4) {
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  -webkit-animation-name: nextVectorDotsYR;
  -moz-animation-name: nextVectorDotsYR;
  -o-animation-name: nextVectorDotsYR;
  animation-name: nextVectorDotsYR;
}

.next-loading-medium-fusion-reactor {
  width: 32px;
  width: var(--loading-medium-size, 32px);
  height: 32px;
  height: var(--loading-medium-size, 32px);
}

.next-loading-medium-fusion-reactor .next-loading-dot {
  width: 8px;
  width: var(--loading-medium-dot-size, 8px);
  height: 8px;
  height: var(--loading-medium-dot-size, 8px);
}

.next-loading-medium-fusion-reactor .next-loading-dot:first-child {
  -webkit-animation-name: nextVectorDotsX-medium;
  -moz-animation-name: nextVectorDotsX-medium;
  -o-animation-name: nextVectorDotsX-medium;
  animation-name: nextVectorDotsX-medium;
}

.next-loading-medium-fusion-reactor .next-loading-dot:nth-child(2) {
  -webkit-animation-name: nextVectorDotsY-medium;
  -moz-animation-name: nextVectorDotsY-medium;
  -o-animation-name: nextVectorDotsY-medium;
  animation-name: nextVectorDotsY-medium;
}

.next-loading-medium-fusion-reactor .next-loading-dot:nth-child(3) {
  -webkit-animation-name: nextVectorDotsXR-medium;
  -moz-animation-name: nextVectorDotsXR-medium;
  -o-animation-name: nextVectorDotsXR-medium;
  animation-name: nextVectorDotsXR-medium;
}

.next-loading-medium-fusion-reactor .next-loading-dot:nth-child(4) {
  -webkit-animation-name: nextVectorDotsYR-medium;
  -moz-animation-name: nextVectorDotsYR-medium;
  -o-animation-name: nextVectorDotsYR-medium;
  animation-name: nextVectorDotsYR-medium;
}

@-webkit-keyframes nextVectorRoute {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  30% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  55% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  80% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-moz-keyframes nextVectorRoute {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  30% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  55% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  80% {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-o-keyframes nextVectorRoute {
  0% {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  30% {
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  55% {
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  80% {
    -webkit-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  to {
    -webkit-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes nextVectorRoute {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  30% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  55% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  80% {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  to {
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -o-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes nextVectorDotsYR {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 16.8px;
    bottom: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    bottom: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-moz-keyframes nextVectorDotsYR {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 16.8px;
    bottom: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    bottom: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-o-keyframes nextVectorDotsYR {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 16.8px;
    bottom: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@keyframes nextVectorDotsYR {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 16.8px;
    bottom: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    bottom: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    bottom: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-webkit-keyframes nextVectorDotsY {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 16.8px;
    top: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    top: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    top: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-moz-keyframes nextVectorDotsY {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 16.8px;
    top: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    top: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    top: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-o-keyframes nextVectorDotsY {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 16.8px;
    top: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    top: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@keyframes nextVectorDotsY {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 16.8px;
    top: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    top: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    top: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    top: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-webkit-keyframes nextVectorDotsX {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 16.8px;
    left: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    left: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    left: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-moz-keyframes nextVectorDotsX {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 16.8px;
    left: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    left: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    left: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-o-keyframes nextVectorDotsX {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 16.8px;
    left: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    left: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@keyframes nextVectorDotsX {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 16.8px;
    left: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    left: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    left: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    left: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-webkit-keyframes nextVectorDotsXR {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 16.8px;
    right: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    right: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    right: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-moz-keyframes nextVectorDotsXR {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 16.8px;
    right: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    right: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    right: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-o-keyframes nextVectorDotsXR {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 16.8px;
    right: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    right: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@keyframes nextVectorDotsXR {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 16.8px;
    right: -webkit-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    right: -moz-calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2/2
    );
    right: calc(
      var(--loading-large-size, 48px) / 2 - var(--loading-large-dot-size, 12px) *
        1.2 / 2
    );
    width: 14.4px;
    width: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    width: calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: 14.4px;
    height: -webkit-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: -moz-calc(var(--loading-large-dot-size, 12px) * 1.2);
    height: calc(var(--loading-large-dot-size, 12px) * 1.2);
  }

  90% {
    right: 0;
    height: 12px;
    height: var(--loading-large-dot-size, 12px);
    width: 12px;
    width: var(--loading-large-dot-size, 12px);
  }
}

@-webkit-keyframes nextVectorDotsYR-medium {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 11.2px;
    bottom: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    bottom: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-moz-keyframes nextVectorDotsYR-medium {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 11.2px;
    bottom: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    bottom: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-o-keyframes nextVectorDotsYR-medium {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 11.2px;
    bottom: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@keyframes nextVectorDotsYR-medium {
  25% {
    bottom: 0;
  }

  45%,
  50% {
    bottom: 11.2px;
    bottom: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    bottom: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    bottom: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    bottom: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-webkit-keyframes nextVectorDotsY-medium {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 11.2px;
    top: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    top: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    top: 0;
    height: 8px;
    width: 8px;
  }
}

@-moz-keyframes nextVectorDotsY-medium {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 11.2px;
    top: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    top: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    top: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-o-keyframes nextVectorDotsY-medium {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 11.2px;
    top: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    top: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@keyframes nextVectorDotsY-medium {
  25% {
    top: 0;
  }

  45%,
  50% {
    top: 11.2px;
    top: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    top: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    top: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    top: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-webkit-keyframes nextVectorDotsX-medium {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 11.2px;
    left: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    left: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    left: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-moz-keyframes nextVectorDotsX-medium {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 11.2px;
    left: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    left: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    left: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-o-keyframes nextVectorDotsX-medium {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 11.2px;
    left: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    left: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@keyframes nextVectorDotsX-medium {
  25% {
    left: 0;
  }

  45%,
  50% {
    left: 11.2px;
    left: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    left: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    left: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    left: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-webkit-keyframes nextVectorDotsXR-medium {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 11.2px;
    right: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    right: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    right: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-moz-keyframes nextVectorDotsXR-medium {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 11.2px;
    right: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    right: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    right: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@-o-keyframes nextVectorDotsXR-medium {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 11.2px;
    right: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    right: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}

@keyframes nextVectorDotsXR-medium {
  25% {
    right: 0;
  }

  45%,
  50% {
    right: 11.2px;
    right: -webkit-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    right: -moz-calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2/2
    );
    right: calc(
      var(--loading-medium-size, 32px) / 2 - var(--loading-medium-dot-size, 8px) *
        1.2 / 2
    );
    width: 9.6px;
    width: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    width: calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: 9.6px;
    height: -webkit-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: -moz-calc(var(--loading-medium-dot-size, 8px) * 1.2);
    height: calc(var(--loading-medium-dot-size, 8px) * 1.2);
  }

  90% {
    right: 0;
    height: 8px;
    height: var(--loading-medium-dot-size, 8px);
    width: 8px;
    width: var(--loading-medium-dot-size, 8px);
  }
}
</style>
