import Vue from 'vue'
import Router from 'vue-router'

// 防止连续点击多次路由报错
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Layout',
      redirect: '/view',
      component: () => import('@/Layout/index.vue'),
      children: [{
        path: '/view',
        name: 'View',
        meta: {
          title: '概览'
        },
        component: () => import('../views/view.vue')
      },
      {
        path: '/applications',
        name: 'App',
        meta: {
          title: '应用'
        },
        component: () => import('../views/applications/index')
      },
      {
        path: '/main',
        name: 'User_main',
        meta: {
          title: '主用户'
        },
        component: () => import('../views/user_main/index')
      },
      {
        path: '/user',
        name: 'User',
        meta: {
          title: '用户'
        },
        component: () => import('../views/user/index')
      },
      {
        path: '/user/add',
        name: 'Add',
        meta: {
          title: '创建用户'
        },
        component: () => import('../views/user/add')
      },
      {
        path: '/user/handle',
        name: 'handle',
        meta: {
          title: '编辑用户'
        },
        component: () => import('../views/user/handle')
      },
      {
        path: '/main/add',
        name: 'Add',
        meta: {
          title: '创建用户'
        },
        component: () => import('../views/user_main/add')
      },
      {
        path: '/main/handle',
        name: 'Add',
        meta: {
          title: '编辑用户'
        },
        component: () => import('../views/user_main/handle')
      },
      {
        path: '/groups',
        name: 'Groups',
        meta: {
          title: '用户组'
        },
        component: () => import('../views/groups/index')
      },
      {
        path: '/groups/add',
        name: 'Add',
        meta: {
          title: '创建用户组'
        },
        component: () => import('../views/groups/add')
      },
      {
        path: '/groups/handle',
        name: 'Handle',
        meta: {
          title: '创建用户组'
        },
        component: () => import('../views/groups/handle')
      },
      {
        path: '/roles',
        name: 'Roles',
        meta: {
          title: '角色'
        },
        component: () => import('../views/roles/index')
      },
      {
        path: '/roles/add',
        name: 'Add',
        meta: {
          title: '新增角色'
        },
        component: () => import('../views/roles/add')
      },
      {
        path: '/roles/handle',
        name: 'Handle',
        meta: {
          title: '编辑角色'
        },
        component: () => import('../views/roles/handle')
      },
      {
        path: '/settings',
        name: 'Settings',
        meta: {
          title: '设置'
        },
        component: () => import('../views/settings')
      },
      {
        path: '/permissions',
        name: 'Permissions',
        meta: {
          title: '授权'
        },
        component: () => import('../views/permissions')
      },
      {
        path: '/policies',
        name: 'Policies',
        meta: {
          title: '权限策略'
        },
        component: () => import('../views/policies')
      },
      {
        path: '/policies/add',
        name: 'Policies',
        meta: {
          title: '创建权限'
        },
        component: () => import('../views/policies/add')
      },
      {
        path: '/policies/handle',
        name: 'Handle',
        meta: {
          title: '编辑权限'
        },
        component: () => import('../views/policies/handle')
      },
      {
        path: '/applications/details',
        name: 'Details',
        meta: {
          title: '详情'
        },
        component: () => import('../views/applications/details')
      },
      {
        path: '/configure',
        name: 'Configure',
        meta: {
          title: '全局配置'
        },
        component: () => import('../views/configure')
      }
      ]
    },
    {
      path: '/:son_username/login',
      name: 'Login',
      component: () => import('../views/login/index')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/login/index')
    }
  ]
})
