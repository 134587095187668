import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { refresh_token, getFunctionTree, logout_token } from '@/api/login'
import { get_refresh_token, removeToken } from '@/utils/auth'
import { setArrayLocalstorage, getArrayLocalstorage, menuHandle } from '@/utils'
import { get_system_text, get_system_image } from '@/api'
import { setFavIcon } from '@/utils/index'
import $_config from '@/config'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    device: 'desktop',
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
      withoutAnimation: false,
      hide: false
    },
    userInfo: getArrayLocalstorage('userInfo'), // 系统用户信息
    menuList: getArrayLocalstorage('menuList'), // 侧边栏数据
    systemText: getArrayLocalstorage('systemText'),
    systemImage: getArrayLocalstorage('systemImage')
  },
  mutations: {
    setMutations (state, { key, value }) {
      state[key] = value
      setArrayLocalstorage(key, value)
    },
    TOGGLE_SIDEBAR: state => {
      if (state.sidebar.hide) {
        return false
      }
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 0)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    SET_SIDEBAR_HIDE: (state, status) => {
      state.sidebar.hide = status
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SideBarHide (state, device) {
      state.sidebar.opened = device
    },
    // 设置网页标题
    setTitle (state, title) {
      state.title = title
    }
  },
  actions: {
    // 修改用户名
    setHeaderUserName ({ state, commit }, name) {
      const obj = state.userInfo
      obj.user_name = name
      commit('setMutations', { key: 'userInfo', value: obj })
    },
    getSystemInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        get_system_text({}).then(da => {
          document.title = da.data.browser_name
          commit('setMutations', { key: 'systemText', value: da.data })
          resolve(da)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSystemInfoImage ({ commit, state }) {
      return new Promise((resolve, reject) => {
        get_system_image({}).then(da => {
          setFavIcon($_config.img_base_url + da.data.BrowserIcon)
          commit('setMutations', { key: 'systemImage', value: da.data })
          resolve(da)
        }).catch(error => {
          reject(error)
        })
      })
    },

    getUserInfo ({ commit }, value) {
      commit('setMutations', { key: 'userInfo', value })
    },
    // 获取侧边栏数据
    getMenuList ({ commit }) {
      return new Promise((resolve, reject) => {
        getFunctionTree({}).then(da => {
          commit('setMutations', { key: 'menuList', value: menuHandle(da.web_info) })
          resolve(da)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut ({ commit }, is_revoke) {
      return new Promise((resolve, reject) => {
        const data = {
          client_uuid: $_config.client_uuid,
          'refresh-token': get_refresh_token(),
          grant_type: 'client',
          is_iam: 'true'
        }

        if (is_revoke) {
          logout_token(data).then(() => {
            resolve()
          }).catch(error => {
            reject(error)
          })
        } else {
          resolve()
          removeToken()
        }
        localStorage.removeItem('loginTime')
        router.replace({ path: '/login' })
        localStorage.clear()
      })
    },
    resetToken () {
      return new Promise((resolve, reject) => {
        const postData = {
          'client_uuid': $_config.client_uuid,
          'grant_type': 'client',
          'refresh-token': get_refresh_token(),
          is_iam: 'true'
        }
        refresh_token(postData).then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    toggleSideBar ({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar ({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleSideBarHide ({ commit }, status) {
      commit('SET_SIDEBAR_HIDE', status)
    },
    toggleDevice ({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    openSideBarHide ({ commit }, device) {
      commit('SideBarHide', device)
    }
  },
  getters: {
    sidebar (state) {
      return state.sidebar
    },
    systemText (state) {
      return state.systemText
    },
    systemImage (state) {
      return state.systemImage
    }
  }
})
