const globalReg = {
  phoneNumber: /^1[3456789]\d{9}$/,
  phoneNumber_international: /^[\d\-（）()\+]+$/,
  name_Chinese: /^[\u4e00-\u9fa5]{2,10}$/,
  name_English: /^[a-zA-Z]{1,20}$/,
  wxAccount: /^[-_a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/,
  qqAccount: /^\d{5,11}$/,
  city: /^[\u4e00-\u9fa5]{1,20}$/,
  email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/,
  password: /^(?![^a-zA-Z]+$)(?!\D+$).{8,20}$/ // 登录密码规则：8-20位，必须包含数字和字母
}
export default globalReg
