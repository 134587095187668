import request from '@/utils/request'

// 获取登陆页logo
export function get_logo () {
  return request({
    url: '/v1/global/login/logo',
    method: 'get'
  })
}

// 账号登录接口
export function signInApi (data) {
  return request({
    url: '/v1/auth/login/login',
    headers: { isToken: false },
    method: 'post',
    data
  })
}

// 账号登录接口
export function login_phoneApi (data) {
  return request({
    url: '/v1/auth/login/login_by_mobile',
    headers: { isToken: false },
    method: 'post',
    data
  })
}

// 账号登录接口
export function getPhoneCode (data) {
  return request({
    url: '/v1/auth/send_phone_verify_code',
    headers: { isToken: false },
    method: 'post',
    data
  })
}

// 账号注册接口
export function register_main_user (data) {
  return request({
    url: '/v1/auth/register_main_user',
    method: 'post',
    data
  })
}

// 账号注册接口
export function send_email_verify_code (data) {
  return request({
    url: '/v1/auth/send_email_verify_code',
    method: 'post',
    data
  })
}

// 忘记密码接口
export function forget_password (data) {
  return request({
    url: '/v1/auth/forget_password',
    method: 'post',
    data
  })
}

// 验证子用户是否存在
export function check_sub_name (username) {
  return request({
    url: '/v1/auth/check_sub_name',
    method: 'post',
    data: {
      sub_username: username
    }
  })
}

// refresh_token更新access_token
export function refresh_token (data) {
  return request({
    url: '/v1/auth/refresh_token',
    method: 'post',
    data
  })
}

// 获取菜单数据
export function getFunctionTree (params) {
  return request({
    url: '/v1/permission/check_user_web_permission',
    method: 'get',
    params
  })
}

// 吊销 access_token
export function revoke_access_token (data) {
  return request({
    url: '/v1/auth/oauth/revoke_access_token',
    method: 'post',
    data
  })
}

// 吊销 refresh_token
export function revoke_refresh_token (data) {
  return request({
    url: '/v1/auth/oauth/revoke_refresh_token',
    method: 'post',
    data
  })
}

// 退出登陆接口
export function logout_token (data) {
  return request({
    url: '/v1/auth/oauth/logout',
    method: 'post',
    data
  })
}
