import Cookies from 'js-cookie'
import config from '../config'
import { removeCookie } from './index'

console.log(`%c配置信息
`, 'color:blue;font-size:30px;width:200px;height:200px;background-image: linear-gradient(135deg,#ff1493,#00bfff);-webkit-background-clip: text;-webkit-text-fill-color: transparent;-webkit-animation: shake-baidu 2s ease 0s infinite;animation: shake-baidu 2s ease 0s infinite;', config)

export function getToken () {
  return Cookies.get(config.tokenKey)
}

export function setToken (token) {
  return Cookies.set(config.tokenKey, token)
}

export function removeToken () {
  removeCookie() // 手动清除Cookies
  Cookies.remove(config.tokenKey)
  Cookies.remove(config.refreshKey)
}

// 获取刷新token
export function get_refresh_token () {
  return Cookies.get(config.refreshKey)
}
