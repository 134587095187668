import request from '@/utils/request'
import CONFIG from '../config'

// 增加主用户
export function main_user (data) {
  return request({
    url: '/v1/account/main_user',
    method: 'post',
    data
  })
}

// 删除主用户
export function delete_main_user (data) {
  return request({
    url: '/v1/account/main_user',
    method: 'delete',
    data
  })
}

// 查看主用户
export function details_main_user (params) {
  return request({
    url: '/v1/account/main_user',
    method: 'get',
    params
  })
}

// 修改主用户
export function edit_main_user (data) {
  return request({
    url: '/v1/account/main_user',
    method: 'put',
    data
  })
}

// 删除子用户
export function delete_sub_user (data) {
  return request({
    url: '/v1/account/sub_user',
    method: 'delete',
    data
  })
}

// 查看主用户列表
export function main_user_list (params) {
  return request({
    url: '/v1/account/main_user_list',
    method: 'get',
    params
  })
}

// 获取当前用户可查看域名
export function domain_list (params) {
  return request({
    url: '/v1/permission/role_domain_info',
    method: 'get',
    params
  })
}

// 重置密码
export function reset_password (params) {
  return request({
    url: '/v1/account/reset_password',
    method: 'get',
    params
  })
}

/** 子用户 */

// 查看子用户列表
export function sub_user_list (params) {
  return request({
    url: '/v1/account/sub_user_list',
    method: 'get',
    params
  })
}

// 获取当前用户可查看域名下拉菜单
export function domain_drop_down_menu (params) {
  return request({
    url: '/v1/account/domain_drop_down_menu',
    method: 'get',
    params
  })
}
// 增加子用户
export function sub_user (data) {
  return request({
    url: '/v1/account/sub_user',
    method: 'post',
    data
  })
}

export function delete_sub_user_user (data) {
  return request({
    url: '/v1/account/sub_user',
    method: 'delete',
    data
  })
}

// 查看主用户
export function details_sub_user (params) {
  return request({
    url: '/v1/account/sub_user',
    method: 'get',
    params
  })
}

// 修改主用户
export function edit_sub_user (data) {
  return request({
    url: '/v1/account/sub_user',
    method: 'put',
    data
  })
}

// 导出用户
export function user_import_or_export (params) {
  return request({
    url: '/v1/account/user_import_or_export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

// 导入用户
export function user_import (data) {
  return request({
    url: '/v1/account/user_import_or_export',
    method: 'post',
    data
  })
}

/** 用户组 */

export function group_list (params) {
  return request({
    url: '/v1/account/group_list',
    method: 'get',
    params
  })
}

// 新增用户组
export function add_group (data) {
  return request({
    url: '/v1/account/group',
    method: 'post',
    data
  })
}

export function rem_group (data) {
  return request({
    url: '/v1/account/group',
    method: 'delete',
    data
  })
}
// 查看用户组
export function get_group (params) {
  return request({
    url: '/v1/account/group',
    method: 'get',
    params
  })
}

// 修改用户组
export function edit_group (data) {
  return request({
    url: '/v1/account/group',
    method: 'put',
    data
  })
}
export function group_drop_down_menu (params) {
  return request({
    url: '/v1/account/group_drop_down_menu',
    method: 'get',
    params
  })
}

export function add_permission (data) {
  return request({
    url: '/v1/permission/grant',
    method: 'post',
    data
  })
}

export function del_permission (data) {
  return request({
    url: '/v1/permission/grant',
    method: 'delete',
    data
  })
}

// 获取所有子用户
export function get_all_sub_user_list (val, domain) {
  return request({
    url: '/v1/account/all_sub_user_list',
    method: 'get',
    params: {
      search: val,
      ...domain
    }
  })
}

// 获取所有用户组

export function get_all_group_list (val, domain) {
  return request({
    url: '/v1/account/all_group_list',
    method: 'get',
    params: { search: val, ...domain }
  })
}

// 获取系统动态图片

export function get_system_image (type) {
  return request({
    url: '/v1/global/flag/image/get_url',
    method: 'get',
    params: {
      image_type: type
    }
  })
}

// 获取系统动态图片

export function set_system_image (data) {
  return request({
    url: '/v1/global/flag/image/upload',
    method: 'post',
    data
  })
}

// 获取系统动态文字

export function get_system_text (type) {
  return request({
    url: '/v1/global/flag/str',
    method: 'get'
  })
}

// 获取系统动态文字

export function set_system_text (data) {
  return request({
    url: '/v1/global/flag/str',
    method: 'put',
    data
  })
}

// 验证token是否可用
export function check_access_token (data) {
  return request({
    url: '/v1/auth/oauth/introspect_access_token',
    method: 'post',
    data: {
      is_iam: true,
      grant_type: 'client',
      client_uuid: CONFIG.client_uuid
    }
  })
}

// 获取父级主账号

export function all_main_user_list () {
  return request({
    url: '/v1/account/domain_drop_down_menu',
    method: 'get'
  })
}

// 查看主用户列表(不用分页)
export function get_all_main_user_list (params) {
  return request({
    url: '/v1/account/all_main_user_list',
    method: 'get',
    params
  })
}

// 修改主用户的合作伙伴
export function edit_account (data) {
  return request({
    url: '/v1/account/user_partner_main',
    method: 'put',
    data
  })
}

// 删除主用户的合作伙伴
export function del_account (data) {
  return request({
    url: '/v1/account/user_partner_main',
    method: 'delete',
    data
  })
}
