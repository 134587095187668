import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUi from 'element-ui'

Vue.use(ElementUi)

import './router/permission'
import 'element-ui/lib/theme-chalk/index.css'
import '@/components/index'
import './styles/element.css'

// 按需引入 iview
import 'view-design/dist/styles/iview.css'
import { Drawer, Tag } from 'view-design'

// 全局注册应用配置
Vue.component('Drawer', Drawer)
Vue.component('Tag', Tag)

import Moment from 'moment'
Vue.prototype.$moment = Moment

import globalReg from '@/common/globalReg.js'
Vue.prototype.GLOBALREG = globalReg

import config from '@/config'
Vue.prototype.$config = config

import './utils/Bus'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
