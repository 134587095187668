<template>
  <div
    dir="ltr"
    role="gridcell"
    class="
      next-row-wrap_12
      next-col next-col-12
      WindDataFieldCol1
      lisQiE
      wind-rc-data-fields-item
    "
    style="padding-right: 16px"
  >
    <div
      dir="ltr"
      role="row"
      class="next-row"
      style="margin-left: -8px; margin-right: -8px"
    >
      <div
        dir="ltr"
        role="gridcell"
        class="
          bunsJr
          next-col next-col-fixed-10
          WindDataFieldCol2
          iTjWwo
          wind-rc-data-fields-item-label
        "
        style="padding-left: 8px; padding-right: 8px"
      >
        <span>{{ label }}</span>
      </div>
      <div
        dir="ltr"
        role="gridcell"
        class="
          next-col next-col-fixed-14
          WindDataFieldCol3
          bJaFBb
          wind-rc-data-fields-item-value
        "
        style="padding-left: 8px; padding-right: 8px"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.lisQiE {
  margin: 0px 0px 8px;
  min-height: 20px;
  line-height: 20px;
}
.next-tabs,
.next-tabs *,
.next-tabs :after,
.next-tabs :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.next-col-12 {
  -ms-flex: 0 0 50%;
  -webkit-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
}
.iTjWwo {
  color: #555;
}
.bJaFBb {
  color: #333;
}
.lisQiE {
  margin: 0px 0px 8px;
  min-height: 20px;
  line-height: 20px;
}

.WindDataFieldCol3 {
  margin-right: 4px;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bunsJr {
  width: 80%;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
