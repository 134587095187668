import axios from 'axios'
import store from '@/store'
import { getToken, get_refresh_token, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { MessageBox, Message, Loading } from 'element-ui'

let loading
const isRelogin = { show: false }

function startLoading () {
  loading = Loading.service({
    lock: true,
    text: '请求中……',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function openMessageBox () {
  return new Promise(resolve => {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      store.dispatch('LogOut', false).then(() => {
        location.href = sessionStorage.getItem('fullPath') || '/view'
        resolve()
      })
    }).catch(() => resolve())
  })
}

function endLoading () {
  loading.close()
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  baseURL: '/api',
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(
  config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false

    if (getToken() && !isToken) {
      config.headers['access-token'] = getToken()
    }

    if (config.method === 'post') {
      console.log('用户点击提交')
      // if (!config.timeout) {
      //   config.timeout = 30000
      // }
      startLoading()
    }

    return config
  }, error => {
    console.log('发生了请求错误')
    endLoading()
    return Promise.reject(error)
  })

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']

  if (res.config.method === 'post') {
    console.log('用户点击正常响应')
    endLoading()
  }
  // 当前用户信息查询异常或用户被已被删除
  if (res.data.status === 407) {
    setTimeout(() => {
      removeToken()
      location.href = '/view'
    }, 3000)
  }

  if (code === 401) {
    if (!isRelogin.show && location.pathname != '/login') {
      isRelogin.show = true
      if (get_refresh_token()) {
        // 刷新token
        store.dispatch('resetToken').then(response => {
          isRelogin.show = false
          if (!response.code && response.msg == 'success') {
            console.log('1')
          } else {
            return Promise.reject(response)
          }
        }).catch(() => {
          openMessageBox().then(() => {
            isRelogin.show = false
          })
        })
      } else {
        openMessageBox().then(() => {
          isRelogin.show = false
        })
      }
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Message({
      message: msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
error => {
  let { message } = error
  if (message == 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  endLoading()
  Message({
    message: message,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})

export default service
